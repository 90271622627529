import React, { useState } from "react";
import MetaTags from "react-meta-tags";
import api from "../../services/api";
import AuthTelas from "../../components/shared/auth/auth";
import Swal from "sweetalert2";

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from "reactstrap";

import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const GestaoDeMacro = () => {
  const [modal, setModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const toggle = () => setModal(!modal);
  const toggleConfirm = () => setConfirmModal(!confirmModal);
  const [admin, setAdmin] = useState(null);
  const [search, setSearch] = useState({
    rgi_principal: "",
  });
  const [dados, setDados] = useState([]);
  const onSubmit = (e) => {
    e.preventDefault();

    if (search.rgi_principal !== "") {
      api.post("/hydrometersmastercdmn/filter", { RGI_PRINCIPAL: search.rgi_principal, LIMIT: 1, TYPE: "0" }).then((response) => {
        console.log(response.data);
        if (response.data.length > 0) {
          setDados(response.data);
        }else{
          setDados([]);        }
        setModal(true);

      });
    }
  }

  const handleDelete = () => {
    if (search.rgi_principal !== "") {
      api.post("/hydrometersmastercdmn/deletemacro", { RGI_PRINCIPAL: search.rgi_principal }).then((response) => {
       
               Swal.fire({
          icon: "success",
          title: "Macro medidor excluído com sucesso",
        })
      });
    }
    // Lógica para excluir os lançamentos vinculados
    console.log("Lançamentos excluídos");
    setModal(false);
    toggleConfirm();
  }

  return (
    <React.Fragment>
      {!admin ?
        <>
        <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
          <AuthTelas onPasswordCheck={setAdmin} />
        </div>
        </> : <>

          <div className="page-content">
            <MetaTags>
              <title>Gestão de dados</title>
            </MetaTags>
            <Container fluid>
              <div className="page-title-box">
                <Row className="align-items-center">
                  <Col md={8}>
                    <h6 className="page-title">Gestão de dados</h6>
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item active">
                        Bem vindo(a) ao IoT DMAE
                      </li>
                    </ol>
                  </Col>
                </Row>
              </div>
              <Row className="justify-content-center">
                <Col md={12} className="text-center">
                  <Card>
                    <CardBody>
                      <form onSubmit={onSubmit}>

                        <Row className="mb-3">
                          <Col md={12}>
                            <h1>Para excluir um macro medidor, entre com seu código abaixo</h1>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col md={4} className="mx-auto">
                            <input
                              className="form-control form-control-lg"
                              type="text"
                              name="NM_RAZAO_SOCIAL"
                              value={search.rgi_principal}
                              onChange={(e) => setSearch({ ...search, rgi_principal: e.target.value })}
                            />
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col md={12} className="text-center">
                            <div className="button-items">
                              <Button
                                color="secondary"
                                className="btn-lg"
                                type="submit"
                              >
                                Pesquisar
                                <i className="dripicons-search" />
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

            </Container>

          </div>

          <Modal isOpen={modal} toggle={toggle} size="xl">
            <ModalHeader
              color="primary"
              toggle={toggle}
              className="d-flex justify-content-center page-title"
            >


            </ModalHeader>
            {dados.length > 0 ? <>
              <ModalBody>
                <h3 className="text-center">Existem lançamentos vinculados a este macro medidor</h3><br></br>
                <table className="table">
                  <thead className="table-light">
                    <tr>
                      <th>NR_HIDROMETRO_IMOVEL</th>
                      <th>NR_IDA_IMOVEL</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{dados[0].NR_HIDROMETRO_IMOVEL}</td>
                      <td>{dados[0].NR_IDA_IMOVEL}</td>
                    </tr>
                  </tbody>
                </table>
                <div className="text-center">
                  <h3>Deseja mesmo excluir todos lançamentos vinculados ao macro medidor {search.rgi_principal}?</h3>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" onClick={toggleConfirm}>Excluir</Button>
                <Button color="secondary" onClick={toggle}>Cancelar</Button>
              </ModalFooter>
            </> : <>
              <ModalBody className="text-center">
                <h3>Nenhum macro medidor encontrado</h3>
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={toggle}>Fechar</Button>
              </ModalFooter>
            </>}
          </Modal>

          <Modal isOpen={confirmModal} toggle={toggleConfirm}>
            <ModalHeader toggle={toggleConfirm}>
              <h5>Confirmação de Exclusão</h5>
            </ModalHeader>
            <ModalBody>
              <p>Você realmente deseja excluir todos os lançamentos vinculados ao macro medidor {search.rgi_principal}?</p>
            </ModalBody>
            <ModalFooter>
              <Button color="danger" onClick={handleDelete}>Confirmar</Button>
              <Button color="secondary" onClick={toggleConfirm}>Cancelar</Button>
            </ModalFooter>
          </Modal>
        </>}
    </React.Fragment>
  );
};

export default GestaoDeMacro;
