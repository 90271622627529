import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { Link, useHistory, useParams } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, Input, Row } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Swal from "sweetalert2";
import api from "../../services/api";

const Calibragem = () => {
  let { state } = useParams();
  let history = useHistory();

  const initialState = {
    ida: "",
    hydro: "",
    lora: "",
    searched: false,
  };
  const [equipSearch, setEquipSearch] = useState(0);
  const [newStatus, setNewStatus] = useState(state);
  const [equipLoc, setEquipLoc] = useState(0);
  const [linkedLora, setLinkedLora] = useState([]);
  const [showLora, setShowLora] = useState([])
  const [notLinkedLora, setNotLinkedLora] = useState([]);
  const [editLora, setEditLora] = useState("")
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [search, setSearch] = useState(initialState);
  const [edit, setEdit] = useState({ watch: "", decLiter: 0, liter: 0, new: 0 });
  const [bodyInitial, setBodyInitial] = useState({})

  const [values, setValues] = useState([]);
  const [equipDMAE, setEquipDMAE] = useState([]);
  const onChangeValue = (event) => {
    if (event.target.value == "linked") {
      setShowLora(linkedLora)
    } else if (event.target.value == "notLinked")
      setShowLora(notLinkedLora)

  }
  const onChangeValueLora = (event) => {
    setEditLora(event.target.value)

  }

  const formatValues = (arr) => {
    arr = {
      base: arr.BASE_TEMPO,
      battery: arr.BATERIA,
      register: arr.DATA_REGISTRO,
      ui: arr.DEVICE_UI,
      gps: arr.GPS_ALTURA,
      hidro: arr.HIDROMETRO,
      local: arr.LOCALIDADE,
      module: arr.NR_MODULO,
      clock: arr.RELOJOARIA,
      rss: arr.RSS,
      snr: arr.SNR,
      pulse: arr.VR_PULSO,
      idEquipDmae: arr.OID_EQUIP_DMAE,
      current: parseInt((((parseInt(arr.VR_PULSO * parseInt(arr.NR_PULSO_LITROS))) / 1000) + parseInt(arr.RELOJOARIA))),

    };
    console.log(arr)
    return arr;
  };
  //LEITURA_ATUAL: parseInt((((parseInt(arr.NR_VLR_PULSO * parseInt(arr.NR_PULSO_LITROS))) / 1000) + parseInt(arr.DS_RELOJOARIA_INICIAL))),
  const getcalib = (bodySearch) => {
    api.post("/calibration", bodySearch).then((response) => {
      let arr = response.data;
      if (arr.length === 0) {
        Swal.fire({
          icon: "warning",
          text: "Nenhum resultado encontrado, refaça a sua busca!",
        });
        setValues([]);
      } else {

        setValues([formatValues(arr[0])]);
        setSearch({ ...initialState, searched: true });
      }
    });

  }

  const submitHandler = (event) => {
    event.preventDefault();
    if (search.hydro === "" && search.lora === "" && search.ida === "") {
      Swal.fire({
        icon: "warning",
        text: "Preencha ao menos um campo",
      });
      return;
    }
    const bodySearch = {
      NR_HIDROMETRO: search.hydro.trim(),
      NR_EQUIPAMENTO: search.lora.trim(),
      CD_IDA: search.ida.trim(),
    };
    setBodyInitial(bodySearch)
    getcalib(bodySearch)


  };
  const getLoras = () => {
    setEditLora("select")
    api.post("/equipmentslora/linked").then((response) => {
      setLinkedLora(response.data.linked)
      setNotLinkedLora(response.data.notLinked)


    }).catch((error) => { console.error("Erro :" + error) })

  }
  const submitHandlerEdit = (event) => {
    event.preventDefault();

    if (
      edit.liter > 9999 ||
      edit.liter < 0 ||
      edit.decLiter > 9999 ||
      edit.decLiter < 0
    ) {
      Swal.fire({
        icon: "warning",
        text: "Valores para Litros e Dec. Litros tem que serem entre 0 e 9999",
      });
      return;
    }
    console.log(edit)
    let aux = equipDMAE;
    let newClock = (Number(values[0].clock) + Number(((edit.leituraHidrometro ? edit.leituraHidrometro : 0) - values[0].current)))
    aux = {
      ...aux,
      DS_RELOJOARIA_INICIAL: newClock,
      NR_LITROS: edit.liter,
      NR_DEC_LITROS: edit.decLiter,
    };
    console.log(aux)
    api
      .put(`/equipmentsdmae/${aux.OID_EQUIPAMENTO_DMAE}`, aux)
      .then((response) => {
      /*  let arr = values
        arr[0].clock = newClock
        setValues(arr)*/
        getcalib(bodyInitial);
        Swal.fire({
          icon: "success",
          title: "Calibragem feita com Sucesso",
        }).then(() => {
          history.push("/calibragem");

        });
      })
      .catch((error) => {
      });
  };

  const onChangeHandler = (prop) => (event) => {
    setSearch({ ...search, [prop]: event.target.value });
  };


  const confirmCalibration = async () => {
    let textConfirm = "Equipamento Substituido"
    let message = ""
    let equipDMAEold = 0
    if (editLora == "empty") {
      message = "Deseja remover o Lora desde equipamento?"
      textConfirm = "Equipamento Removido"
      toggle();
    } else {
      if (editLora == "select") {
        return ""
      }
      const body = {
        OID_HIDROMETRO: "",
        OID_EQUIPAMENTO_LORA: editLora,
        DS_LOCALIDADE: "",
        DS_LOCALIZACAO_REGIAO: "",
        TP_LOCALIZACAO: "",
      }

      toggle();

      await api.post("/equipmentsdmae/filter", body)
        .then((response) => {
          let arr = response.data;

          if (arr.length == 0) {
            message = "Equipamento Lora não vinculado, deseja vincula-lo?"
          } else if (arr.length == 1) {
            message = "Equipamento Lora vinculado ao endereço\n\n " + arr[0].DS_LOCALIDADE + "\n\n Deseja substitui-lo?"
            equipDMAEold = arr[0].OID_EQUIPAMENTO_DMAE

          }




        }).catch((e) => { console.error("erro" + e) })
    }



    Swal.fire({
      title: message,
      // text: "You won't be able to revert this!",
      //  icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: "Não"
    }).then((result) => {
      if (result.isConfirmed) {
        const body = {
          OID_EQUIPAMENTO_DMAE: equipSearch,
          OID_EQUIPAMENTO_LORA: editLora,
          OID_EQUIPAMENTO_DMAE_OLD: equipDMAEold
        }

        api.post("/equipmentsdmae/updateCalibration", body).then(

          setSearch(initialState)
        )

        Swal.fire(
          textConfirm,
          '',
          'success'
        )

      } else {
        setModal(true)
        setEditLora("select")
      }
    })
  }
  const onChangeHandlerEdit = (prop) => (event) => {
    const value = event.target.value.replace(/[^0-9]/g, '');
    setEdit({ ...edit, [prop]: value });
  };

  useEffect(() => {
    if (newStatus) {
      const body = { NR_EQUIPAMENTO: newStatus };
      api.post("/calibration", body).then((response) => {
        const arr = response.data;
        setValues([formatValues(arr[0])]);
        setSearch({ ...initialState, searched: true });

        let idHidro = 0;
        const bodyHidroFilter = {
          NR_HIDROMETRO: arr[0].HIDROMETRO,
        };
        api
          .post("/hydrometers/filter", bodyHidroFilter)
          .then((resp) => {
            const arr = resp.data[0];
            idHidro = arr.OID_HIDROMETRO;
          })
          .then(() => {
            api
              .post("/equipmentsdmae/filter", {
                OID_HIDROMETRO: idHidro,
              })
              .then((response) => {
                let arr2 = response.data[0];
                setEquipDMAE(arr2);
                setEdit({
                  new: arr2.DS_RELOJOARIA_INICIAL,
                  watch: arr2.DS_RELOJOARIA_INICIAL,
                  decLiter: arr2.NR_DEC_LITROS || 0,
                  liter: arr2.NR_LITROS || 0,
                });
              });
          });
      });
    }
  }, [newStatus]);
  const setColor = (color) => {

    color = parseInt(color)

    if (color == 100) {
      return "#228B22";
    } else if (color > 70) {
      return "#00FF7F"
    } else if (color > 35) {
      return "#f8722d"
    } else {
      return "#CD5C5C"
    }


  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Calibragem</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">Calibragem</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Bem vindo(a) ao IoT DMAE
                  </li>
                </ol>
              </Col>
            </Row>
          </div>
        </Container>

        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <form onSubmit={submitHandler}>
                  <Row className="mb-3">
                    <label htmlFor="codigo" className="col-md-2 col-form-label">
                      IDA
                    </label>
                    <div className="col-md-6">
                      <Input
                        className="form-control form-control-lg"
                        type="text"
                        onChange={onChangeHandler("ida")}
                        value={search.ida}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label htmlFor="codigo" className="col-md-2 col-form-label">
                      Hidrômetro
                    </label>
                    <div className="col-md-6">
                      <Input
                        className="form-control form-control-lg"
                        type="text"
                        onChange={onChangeHandler("hydro")}
                        value={search.hydro}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label htmlFor="codigo" className="col-md-2 col-form-label">
                      Equipamento Lora
                    </label>
                    <div className="col-md-6">
                      <Input
                        className="form-control form-control-lg"
                        type="text"
                        onChange={onChangeHandler("lora")}
                        value={search.lora}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <div className="button-items text-center">
                      <Button
                        color="secondary"
                        className="btn-lg"
                        type="submit"
                      >
                        Pesquisar
                        <i className="dripicons-search" />
                      </Button>
                    </div>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Container fluid>
          <div className="table-responsive">
            <table className="table align-middle table-nowrap mb-0 overflow-hidden">
              <thead className="table-light">
                <tr>
                  <th className="align-middle">Hidrômetro</th>
                  <th className="align-middle">Módulo LORA</th>
                  <th className="align-middle">Localidade</th>
                  <th className="align-middle">Relojoaria</th>
                  <th className="align-middle">Device UI</th>
                  <th className="align-middle">SNR</th>
                  <th className="align-middle">RSS</th>
                  <th className="align-middle">GPS</th>
                  <th className="align-middle">Pulso</th>
                  <th className="align-middle">Bateria</th>
                  <th className="align-middle">Base Tempo</th>
                  <th className="align-middle">Data</th>
                  <th className="align-middle">Hora</th>
                  <th className="align-middle"> </th>
                  <th className="align-middle"> </th>
                </tr>
              </thead>
              <tbody>
                {search.searched ? (
                  values?.map((calib, key) => (
                    <tr key={"_tr_" + key}>
                      <td>{calib.hidro}</td>
                      <td>{calib.module}</td>
                      <td>{calib.local}</td>
                      <td>{calib.clock}</td>
                      <td>{calib.ui}</td>
                      <td>{calib.snr}</td>
                      <td>{calib.rss}</td>
                      <td>{calib.gps}</td>
                      <td>{calib.pulse}</td>
                      <td><span style={{ border: "solid 4px", color: setColor(calib.battery) }}>{calib.battery}</span></td>
                      <td>{calib.base}</td>
                      <td>
                        {new Date(calib.register).toLocaleDateString()}
                      </td>
                      <td>
                        {new Date(calib.register).toLocaleTimeString()}</td>
                      <td>

                        <Link
                          type="button"
                          to={`/cadastro/acoesequipamento-update/${calib.module}/calib`}
                          color="link"
                          size="sm"
                          className="btn btn-light waves-effect waves-light"
                        >
                          <i className="dripicons-cloud-upload" /> Enviar dados
                        </Link>
                      </td>
                      <td>

                        <button
                          type="button"
                          color="link"
                          size="sm"
                          className="btn btn-light waves-effect waves-light"
                          onClick={() => setNewStatus(calib.module)}
                        >
                          <i className="dripicons-alarm" />
                        </button>
                      </td>
                      <td>
                        <Link type="button" onClick={() => { toggle(); setEquipSearch(calib.idEquipDmae); setEquipLoc(calib.local); getLoras(); }}
                          color="link"
                          size="sm"
                          className="btn btn-light waves-effect waves-light"

                        ><i className="dripicons-cloud-upload" /> Substituição</Link>

                      </td>
                    </tr>
                  ))
                ) : (
                  <></>
                )}
              </tbody>
            </table>
          </div>

          <Modal isOpen={modal} toggle={toggle} className="w-50">
            <ModalHeader color="primary" toggle={toggle} className="d-flex justify-content-center page-title"><h2>Editar equipamento da localização:</h2><span className="d-flex justify-content-center page-title"> {equipLoc}</span> </ModalHeader>
            <ModalBody>
              <Row className="mb-3">
                <h3 className="d-flex justify-content-center">Tipo de Lora</h3>
                <div onChange={onChangeValue} className="d-flex justify-content-center page-title">

                  <input type="radio" value="notLinked" name="Lora" id="notRegisterLora" className="form-check-input" />
                  <label for="notRegisterLora"
                  > &emsp;DISPONIVEL &emsp;&emsp;
                  </label>

                  <input type="radio" value="linked" name="Lora" id="registerLora" className="form-check-input" />
                  <label for="registerLora"
                  >&emsp;ASSOCIADO
                  </label>

                </div>
                <div className="d-flex justify-content-center page-title">
                  <div className="col-md-6">

                    <label
                      htmlFor="OID_EQUIPAMENTO_LORA"
                      className=" col-form-label "
                    >
                      Equipamento Lora
                    </label>

                    <select
                      name="OID_EQUIPAMENTO_LORA"
                      className="form-control form-control-lg"
                      onChange={onChangeValueLora}
                    >
                      <option value="select">Selecione...</option>
                      <option value="empty" >Vazio</option>
                      {showLora?.map((lora) => (
                        <option key={lora.NR_EQUIPAMENTO} value={lora.OID_EQUIPAMENTO_LORA}>
                          {lora.NR_EQUIPAMENTO}
                        </option>
                      ))}




                    </select>
                  </div>
                </div>

                <div className="btn-lg d-flex justify-content-center mt-3"                >
                  <Button
                    color="primary"
                    className="btn-lg"
                    type="submit"
                    onClick={confirmCalibration}
                  >
                    Atualizar

                  </Button>
                </div>
              </Row>
            </ModalBody>
            <ModalFooter>

            </ModalFooter>
          </Modal>
        </Container>
        {newStatus ? (
          <Container fluid>
            <div className="table-responsive mt-5" style={{ fontSize: "110%" }}>
              <form onSubmit={submitHandlerEdit} style={{ overflow: "hidden" }}>
                <Row className="mb-1">
                  <label htmlFor="codigo" className="col-md-2 col-form-label">
                    Leitura Atual da telemetria
                  </label>
                  <div className="col-md-2">
                    <Input
                      style={{ backgroundColor: "#d3d3d3" }}
                      className="form-control form-control-lg"
                      type="text"
                      readOnly
                      value={values[0].current}
                    />
                  </div>
                </Row>
                <Row className="mb-1">
                  <label htmlFor="decLiter" className="col-md-2 col-form-label">
                    Leitura do Hidrometro
                  </label>
                  <div className="col-md-2">
                    <Input

                      className="form-control form-control-lg"
                      type="text"
                      onChange={onChangeHandlerEdit("leituraHidrometro")}
                      value={edit.leituraHidrometro}
                    />
                  </div>
                </Row>
                <Row className="mb-1">
                  <label htmlFor="liter" className="col-md-2 col-form-label">
                    Diferença de Consumo
                  </label>
                  <div className="col-md-2">
                    <Input
                      style={{ backgroundColor: "#d3d3d3" }}

                      className="form-control form-control-lg"
                      type="text"
                      onChange={onChangeHandlerEdit("watch")}
                      value={(edit.leituraHidrometro ? edit.leituraHidrometro : 0) - values[0].current}
                      readOnly
                    />
                  </div>

                </Row>
                <Row className="mb-1">
                  <label htmlFor="liter" className="col-md-2 col-form-label">
                    Nova Relojoaria:
                  </label>
                  <div className="col-md-2">
                    <Input
                      style={{ backgroundColor: "#d3d3d3" }}
                      onChange={onChangeHandlerEdit("new")}

                      className="form-control form-control-lg"
                      type="text"
                      value={Number(values[0].clock) + Number(((edit.leituraHidrometro ? edit.leituraHidrometro : 0) - values[0].current))}
                      readOnly
                    />
                  </div>

                </Row>
                <Row className="mb-1">
                  <div className="col-md-2" style={{ marginTop: "1rem" }}>
                    <Button color="primary" className="btn-lg" type="submit">
                      Atualizar
                    </Button>
                  </div>
                </Row>
              </form>
            </div>
          </Container>
        ) : (
          <></>
        )}
      </div>
    </React.Fragment>
  );
};

export default Calibragem;
