import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import ReactInputMask from "react-input-mask";

import {
  Col,
  Card,
  CardBody,
  Container,
  Row,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useHistory,
} from "react-router-dom";

import axios from "axios";
import api from "../../services/api";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import ExcelFile from "helpers/ExcelPlugin/components/ExcelFile";
import ExcelSheet from "react-data-export/dist/ExcelPlugin/elements/ExcelSheet";
import ExcelColumn from "react-data-export/dist/ExcelPlugin/elements/ExcelColumn";
import { getByDisplayValue } from "@testing-library/react";

const editHidrometro = () => {
  let history = useHistory();
  const [reload, setReload] = useState(false);
  const [hydrometers, setHydrometers] = useState([]);
  const [idHidrometro, setIdHidrometro] = useState(0);
  const [numero, setNumero] = useState("");
  const [consumo, setConsumo] = useState("");
  const [vazao, setVazao] = useState("");
  const [modelo, setModelo] = useState("");
  const [desc, setDesc] = useState("");
  const [ativo, setAtivo] = useState("");
  const [acesso, setAcesso] = useState("L");
  const [nmCondominio, setNmCondominio] = useState("");


  const [capacidade, setCapacidade] = useState("");
  const [textCapacity, setTextCapacity] = useState("");
  const [selecionados, setSelecionados] = useState([]);
  const [master, setMaster] = useState("");

  const [checkboxValue, setCheckboxValue] = useState("");
  const [modalShow, setmodalShow] = useState(false);
  const [modalDelete, setmodalDelete] = useState(false);
  const [nrHydro, setNrHydro] = useState('');
  const [deleteType, setDeleteType] = useState('unico');


  const [hydrometersFree, setHydrometersFree] = useState([]);
  const [empresas, setEmpresas] = useState([]);
  const [empresa, setEmpresa] = useState("");
  const [telefone, setTelefone] = useState("");
  const [macro, setMacro] = useState([]);
  const [macroPadrao, setMacroPadrao] = useState('0')

  const [alocados, setAlocados] = useState({
    qtd: 0,
    qtd_alocados: 0
  });
  const toggle = () => setmodalDelete(!modalDelete);

  let { id } = useParams();
  useEffect(() => {
    getEmpresas();
    hydro();
    refreshUnique();
  }, []);
  const refreshUnique = () => {
    api.get("/hydrometersmastercdmn/macro/" + id).then((response) => {

      setMacro(response.data)
      if (response.data.length == 1) {
        newMacro(response.data[0].CD_LIGACAO_RGI_PRINCIPAL)
        setMacroPadrao(response.data[0].CD_LIGACAO_RGI_PRINCIPAL);
      }

    });
  }
  useEffect(() => {
    api.get("/hydrometersmastercdmn/" + id).then((response) => {
      setHydrometers(response.data);
    });
  }, [reload]);

  /*useEffect(() => {
    getHydrometersFree();
  }, [reload]);
  */const getEmpresas = () => {
    api.post("/empresaservice/empresas").then((response) => {
      response.data.map((elem) => {
        empresas.push({
          id: elem.OID_EMPRESA,
          name: elem.NM_RAZAO_SOCIAL,
        });
      });
      setEmpresas(empresas);
    });
  };


  const getHydrometersFree = () => {
    api.get("/hydrometersmastercdmn").then((response) => {
      setHydrometersFree(response.data);
    });
  };


  const hydro = () => {
    api.get(`/hydrometers/${id}`).then((response) => {
      const arr = response.data[0];
      console.log(arr)
      setIdHidrometro(arr.OID_HIDROMETRO);
      setNumero(arr.NR_HIDROMETRO);
      setConsumo(arr.NR_CONSUMO_LITROS);
      setVazao(arr.NR_RANGE_VAZAO);
      setModelo(arr.DS_MODELO_HIDROMETRO);
      setDesc(arr.DS_DESCRICAO_HIDROMETRO);
      setAtivo(arr.TP_ATIVO);
      setAcesso(arr.TP_ACESSO);
      setNmCondominio(arr.NM_CONDOMINIO ? arr.NM_CONDOMINIO : "");
      setCapacidade(arr.ID_CAPACIDADE != null ? arr.ID_CAPACIDADE : -1);
      setCheckboxValue(arr.TP_HIDROMETRO);
      setMaster(arr.OID_HIDROMETRO);
      setEmpresa(arr.OID_EMPRESA);
      setTelefone(arr.NR_CONTATO ? arr.NR_CONTATO.toString() : "")
    });
  };

  const Excluir = (id) => {
    api
      .delete(`/hydrometersmastercdmn/${id}`)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Hidrômetro deletado!",
        }).then(() => {
          setReload(!reload);
        });
      })
      .catch(function (error) {
        Swal.fire({
          icon: "warning",
          title: "Falha na deleção, tente novamente",
        });
      });
  };
  const confirmDelete = () => {
    api.delete(`/hydrometersmastercdmn/unique/` + nrHydro).then((response) => {
      refreshUnique();
      Swal.fire({
        icon: "success",
        text: "Hidrometro Deletado",
        timer: 2000,
      });
      newMacro(macroPadrao)
    }).catch((error) => {
      Swal.fire({
        icon: "warning",
        text: "Não foi possível fazer a exclusão, por favor contate o administrador!",
      });
    })

  }

  const modal2 = () => {
    setmodalShow(true);
  };

  async function saveinliste() {
    for (let i = 0; i < selecionados.length; i++) {
      const body = {
        OID_HIDROMETRO_MASTER: master,
        NR_HIDROMETRO_CDMN: selecionados[i],
      };

      await api.post("/hydrometersmastercdmn", body).then((response) => { });
    }
    setReload(!reload);
    setmodalShow(false);
  }
  async function deleteinliste() {
    let nr_hydro = [];

    for (let i = 0; i < selecionados.length; i++) {

      nr_hydro.push(selecionados[i])

      console.log(nr_hydro)

      //  await api.post("/hydrometersmastercdmn", body).then((response) => { });
    }
    if (nr_hydro.length > 0) {



      const body = {
        NR_HIDROMETRO_CDMN: "'" + nr_hydro.join("','") + "'"
      }
      await api.post("/hydrometersmastercdmn/delete/", body).then((response) => {
        Swal.fire({
          icon: "sucess",
          title: "Excluído com sucesso!",
        });
      })

    } else {
      Swal.fire({
        icon: "warning",
        title: "Selecione algum hidrometo para a exclusão",
      });
    }
    newMacro(macroPadrao);
    setReload(!reload);
  }
  function msn() {
    setCheckboxValue("M");
    Swal.fire({
      icon: "warning",
      title: "Remova os hidrômetros filhos para alterar o tipo",
    });
  }
  const onSubmit = (event) => {
    event.preventDefault();
    let body = {
      OID_HIDROMETRO: idHidrometro,
      NR_HIDROMETRO: numero,
      NR_CONSUMO_LITROS: consumo,
      NR_RANGE_VAZAO: vazao,
      DS_MODELO_HIDROMETRO: modelo,
      DS_DESCRICAO_HIDROMETRO: desc,
      TP_ATIVO: ativo,
      ID_CAPACIDADE: capacidade,
      TP_HIDROMETRO: checkboxValue,
      OID_EMPRESA: empresa,
      NR_CONTATO: telefone.replace(/\D/g, ""),
      TP_ACESSO: acesso,
      NM_CONDOMINIO: nmCondominio,
    };
    if (body.NR_CONTATO.length != 10 && body.NR_CONTATO.length != 11 && body.NR_CONTATO.length != 0) {
      Swal.fire({
        icon: "warning",
        title: "Contato cadastrado Incorreto. Favor verifique o contato e tente novamente",
      });
      return
    }
    if (body.ID_CAPACIDADE == -1) {
      Swal.fire({
        icon: "warning",
        title: "Selecione a capacidade do Hidrometro",
      });
      return
    }
    if (body.TP_ACESSO == "") {
      Swal.fire({
        icon: "warning",
        title: "Selecione o tipo de acesso do Hidrometro",
      });
      return
    }

    api
      .put(`/hydrometers/${idHidrometro}`, body)
      .then((response) => {
        /*     console.log(
               "🚀 ~ file: Hidrometro-edit.js:154 ~ .then ~ response",
               body
             );*/
        Swal.fire({
          icon: "success",
          title: "Hidrômetro atualizado!",
        }).then(() => {
          history.push("/cadastro/hidrometro");
        });
      })
      .catch(function (error) {
        Swal.fire({
          icon: "warning",
          title: "Falha na Atualização, tente novamente",
        });
      });
  };

  const formatArray = (data) => {
    let formated = {
      id: data.OID_HIDROMETRO,
      numero: data.NR_HIDROMETRO,
      consumo: data.NR_CONSUMO_LITROS,
      vazao: data.NR_RANGE_VAZAO,
      modelo: data.DS_MODELO_HIDROMETRO,
      desc: data.DS_DESCRICAO_HIDROMETRO,
      ativo: data.TP_ATIVO,
      capacidade: data.ID_CAPACIDADE,
      telefone: data.NR_CONTATO
    };
    return formated;
  };

  const handleInputs = (prop) => (event) => {
    if (prop === "numero") {
      setNumero(event.target.value);
    } else if (prop === "desc") {
      setDesc(event.target.value);
    } else if (prop === "modelo") {
      setModelo(event.target.value);
    } else if (prop === "consumo") {
      setConsumo(event.target.value);
    } else if (prop === "vazao") {
      setVazao(event.target.value);
    } else if (prop === "telefone") {
      setTelefone(event.target.value);
    }
  };

  const handleSelect = (event) => {
    setAtivo(event.target.value);
  };
  const handleSelectAcess = (event) => {
    setAcesso(event.target.value);
  };
  const handleNMCmd = (event) => {
    setNmCondominio(event.target.value);
  };

  const handleSelectCapacidade = (event) => {
    setCapacidade(event.target.value);
    if (event.target.value == -1) {
      setTextCapacity("")
    } else if (event.target.value == 0) {
      setTextCapacity("4 Digitos: 9999")
    } else if (event.target.value == 1) {
      setTextCapacity("5 Digitos: 99999")
    } else if (event.target.value == 2) {
      setTextCapacity("6 Digitos: 999999")
    } else if (event.target.value == 3) {
      setTextCapacity("7 Digitos: 9999999")
    } else if (event.target.value == 4) {
      setTextCapacity("8 Digitos: 99999999")
    } else if (event.target.value == 5) {
      setTextCapacity("9 Digitos: 999999999")
    }
  };
  const newMacro = (event) => {

    let value = event?.target ? event.target.value : event;
    if (value != 0) {
      api.post("/hydrometersmastercdmn/filter", { RGI_PRINCIPAL: value, LIMIT: null, TYPE: "1" }).then((response) => {
        setHydrometersFree(response.data);
        console.log('1')
      });

      api.post("/hydrometersmastercdmn/filter", { RGI_PRINCIPAL: value, LIMIT: 1, TYPE: "0" }).then((response) => {
        setAlocados({ qtd: response.data[0].QUANTIDADE_TOTAL, qtd_alocados: response.data[0].QUANTIDADE_TOTAL_ALOCADA });
        console.log(response.data[0])
      });


    } else {
      setHydrometersFree([]);


    }
  }
  /**
   * {
    "NR_IDA": "",
    "CD_META_DEVICE_ADDR": "",
    "NR_HIDROMETRO": "",
    "NR_CONTA_IMOVEL": "",
    "DT_FILTER_INI": "2024-09-28",
    "DT_FILTER_FIM": "2024-10-01",
    "SERVICE": "",
    "RGI_PRINCIPAL": "00020102150700099000"
}
   */
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Cadastro - Hidrometro</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <Link to="/cadastro/hidrometro">
                  <Button
                    color="link"
                    className="btn btn-lg btn-link waves-effect"
                  >
                    <i className="dripicons-arrow-thin-left" /> Voltar
                  </Button>
                </Link>
              </Col>
            </Row>
          </div>
        </Container>

        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <form onSubmit={onSubmit}>
                  <Row className="mb-3">
                    <label htmlFor="codigo" className="col-md-2 col-form-label">
                      Número Hidrômetro
                    </label>
                    <div className="col-md-6">
                      <input
                        required
                        className="form-control form-control-lg"
                        type="text"
                        name="NR_HIDROMETRO"
                        value={numero}
                        onChange={handleInputs("numero")}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="descricao"
                      className="col-md-2 col-form-label"
                    >
                      Descrição
                    </label>
                    <div className="col-md-6">
                      <input
                        required
                        className="form-control form-control-lg"
                        type="text"
                        name="DS_DESCRICAO_HIDROMETRO"
                        value={desc}
                        onChange={handleInputs("desc")}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="descricao"
                      className="col-md-2 col-form-label"
                    >
                      Modelo
                    </label>
                    <div className="col-md-6">
                      <input
                        required
                        className="form-control form-control-lg"
                        type="text"
                        name="DS_MODELO_HIDROMETRO"
                        value={modelo}
                        onChange={handleInputs("modelo")}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label htmlFor="Vazão" className="col-md-2 col-form-label">
                      Vazão
                    </label>
                    <div className="col-md-6">
                      <input
                        required
                        className="form-control form-control-lg"
                        type="text"
                        name="NR_RANGE_VAZAO"
                        value={vazao}
                        onChange={handleInputs("vazao")}
                      />
                    </div>
                  </Row>
                  <Row className="mb-2">
                    <div className="col-md-2">
                      <label htmlFor="descricao" className="switch">
                        Status
                      </label>
                    </div>
                    <div className="col-md-6">
                      <select
                        id="TP_ATIVO"
                        className="form-control form-control-lg"
                        onChange={handleSelect}
                        value={ativo}
                      >
                        <option value="S">Ativo</option>
                        <option value="N">Inativo</option>
                      </select>
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="descricao"
                      className="col-md-2 col-form-label"
                    >
                      Pulso/litro
                    </label>
                    <div className="col-md-6">
                      <input
                        required
                        className="form-control form-control-lg"
                        type="text"
                        name="NR_CONSUMO_LITROS"
                        value={consumo}
                        onChange={handleInputs("consumo")}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="descricao"
                      className="col-md-2 col-form-label"
                    >
                      Telefone
                    </label>
                    <div className="col-md-6">

                      <ReactInputMask
                        mask="(99)99999-9999"
                        className="form-control form-control-lg"
                        type="text"
                        value={telefone}
                        onChange={handleInputs("telefone")}
                      />
                    </div>
                  </Row>
                  <Row className="mb-2">
                    <div className="col-md-2">
                      <label htmlFor="descricao">
                        Capacidade
                      </label>
                    </div>
                    <div className="col-md-6">
                      <select
                        id="ID_CAPACIDADE"
                        className="form-control form-control-lg"
                        onChange={handleSelectCapacidade}
                        value={capacidade}
                      >
                        <option value="-1">Selecionar</option>
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>

                      </select>
                      <span className="text-info">{textCapacity}</span>
                    </div>
                  </Row>
                  <Row className="mb-2">
                    <div className="col-md-2">
                      <label htmlFor="descricao">
                        Acesso
                      </label>
                    </div>
                    <div className="col-md-6">
                      <select
                        id="ID_ACESSO"
                        className="form-control form-control-lg"
                        onChange={handleSelectAcess}
                        value={acesso}
                      >
                        <option value="L">Livre</option>
                        <option value="IT">Interno Trancado</option>
                        <option value="ET">Externo Trancado</option>
                      </select>
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="descricao"
                      className="col-md-2 col-form-label"
                    >
                      Master
                    </label>
                    <div className="col-md-6">
                      <input
                        className="form-check-input p-2"
                        type="checkbox"
                        name="TP_HIDROMETRO"
                        value="U"
                        checked={checkboxValue !== "U"}
                        onChange={() =>
                          setCheckboxValue(
                            hydrometers.length === 0
                              ? checkboxValue === "M"
                                ? "U"
                                : "M"
                              : msn()
                          )
                        }
                      />
                    </div>
                  </Row>
                  {checkboxValue === "M" ? (<>
                    <Row className="mb-3">
                      <label
                        htmlFor="descricao"
                        className="col-md-2 col-form-label"
                      >
                        Empresa Responsável
                      </label>
                      <div className="col-md-6">
                        <select
                          className="form-control form-control-lg"
                          name="OID_EMPRESA"
                          onChange={(e) => setEmpresa(e.target.value)}
                          value={empresa}
                        >
                          <option value="">Selecionar</option>
                          {empresas.map((empresa, index) => (
                            <option key={index} value={empresa.id}>
                              {empresa.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label htmlFor="NM_CONDOMINIO" className="col-md-2 col-form-label">
                        Nome do Condominio
                      </label>
                      <div className="col-md-6">
                        <input
                          onChange={handleNMCmd}
                          className="form-control form-control-lg"
                          type="text"
                          name="NM_CONDOMINIO"
                          value={nmCondominio}
                        />
                      </div>
                    </Row>
                  </>
                  ) : (
                    <></>
                  )}
                  <Modal isOpen={modalShow} size="lg">
                    <ModalHeader toggle={() => setmodalShow(false)}>
                      Adicionar Hidrômetros
                    </ModalHeader>
                    <Container fluid>
                      <Row className="mb-3">
                        { }
                        <label
                          htmlFor="RGI_PRINCIPAL"
                          className="col-md-6 col-form-label"
                        >
                          Principal/Macro medidor
                        </label>

                        <div className="col-md-6">
                          <select
                            name="RGI_PRINCIPAL"
                            className="form-control form-control-lg"
                            onChange={newMacro}
                          >
                            {macro.length > 2 ? <option value="0">Principal/Macro medidor</option> : <></>}
                            {macro?.map((elem, key) => (
                              <option key={key} value={elem.CD_LIGACAO_RGI_PRINCIPAL}>
                                {elem.NM_CONDOMINIO ? elem.NM_CONDOMINIO : elem.CD_LIGACAO_RGI_PRINCIPAL}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Row>
                    </Container>
                    <Container fluid
                      style={{ display: hydrometersFree.length > 0 ? "block" : "none" }}
                    >
                      <div className="table-responsive">
                        <table className="table align-middle table-nowrap mb-0">
                          <thead className="table-light">
                            <tr>
                              <th className="align-middle"></th>
                              <th className="align-middle">
                                Número Hidrômetro
                              </th>
                              <th className="align-middle">Bloco</th>
                              <th className="align-middle">Complemento</th>
                              <th className="align-middle">IDA</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {hydrometersFree?.map((hydrometer, key) => (
                              <tr key={"_tr_" + key}>
                                <td>
                                  <input
                                    type="checkbox"
                                    onChange={() => {
                                      selecionados.push(
                                        hydrometer.NR_HIDROMETRO_IMOVEL
                                      );
                                    }}
                                  />
                                </td>
                                <td>{hydrometer.NR_HIDROMETRO_IMOVEL}</td>
                                <td>{hydrometer.DS_BLOCO_IMOVEL}</td>
                                <td>{hydrometer.DS_COMPLEMENTO_IMOVEL}</td>
                                <td>{hydrometer.NR_IDA_IMOVEL}</td>
                                <td>
                                  <Button
                                    type="button"
                                    onClick={() => { toggle(); setDeleteType('unico'); setNrHydro(hydrometer.NR_HIDROMETRO_IMOVEL); }}
                                    color="link"
                                    size="sm"
                                    className="btn-light waves-effect waves-light"
                                  >
                                    <i className="dripicons-trash text-danger" />
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </Container>
                    <ModalFooter>
                      <Button
                        style={{ display: hydrometersFree.length > 0 ? "block" : "none" }}
                        color="danger"
                        onClick={() => {
                          setDeleteType('multi');
                          toggle();

                        }}
                      >
                        Deletar Selecioandos
                      </Button>
                      <Button
                        style={{ display: hydrometersFree.length > 0 ? "block" : "none" }}
                        color="primary"
                        onClick={() => {
                          saveinliste();
                        }}
                      >
                        Salvar
                      </Button>
                    </ModalFooter>
                  </Modal>
                  <Modal isOpen={modalDelete} toggle={() => { toggle() }} size="lg">
                    <ModalHeader color="primary" className="d-flex justify-content-center page-title">
                      {deleteType == 'unico' ?
                        <><h2>Deseja deletar o hidrometro {nrHydro} ?</h2></>
                        : <><h2>Deseja deletar os hidrometros selecionados?</h2></>}
                    </ModalHeader>
                    <ModalBody>
                      <Row className="mb-3">

                        <div className="btn-lg d-flex justify-content-center mt-3"                >
                          <Button
                            color="primary"
                            className="btn-lg mx-2"
                            type="submit"
                            onClick={() => {
                              deleteType == 'unico' ? confirmDelete() : deleteinliste(); toggle();
                            }}
                          >
                            Deletar
                          </Button>
                          <Button
                            color="primary"
                            className="btn-lg mx-2"
                            type="submit"
                            onClick={() => { toggle(); }}
                          >
                            Cancelar
                          </Button>
                        </div>
                      </Row>
                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                  </Modal>

                  <Button
                    color="primary"
                    className="btn-lg"
                    style={{ marginBottom: 10 }}
                    type="submit"
                  >
                    Atualizar
                  </Button>
                </form>
                {checkboxValue !== "U" ? (
                  <>
                    <Container fluid>
                      <div className="table-responsive">
                        <h2>Hidrômetros Relacionados</h2>
                        <table className="table align-middle table-nowrap mb-0">
                          <thead className="table-light">
                            <tr>
                              <th className="align-middle">
                                Número Hidrômetro
                              </th>
                              
                              <th className="align-middle">Bloco</th>
                              <th className="align-middle">Complemento</th>
                              <th className="align-middle">IDA</th>
                              <th className="align-middle">
                                Apartamentos alocados: {alocados.qtd_alocados}/{alocados.qtd}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {hydrometers?.map((hydrometer, key) => (
                              <tr key={"_tr_" + key}>
                                <td>{hydrometer.NR_HIDROMETRO_IMOVEL}</td>
                                <td>{hydrometer.DS_BLOCO_IMOVEL}</td>
                                <td>{hydrometer.DS_COMPLEMENTO_IMOVEL}</td>
                                <td>{hydrometer.NR_IDA_IMOVEL}</td>
                                
                                <td>
                                  <Link
                                    type="button"
                                    color="link"
                                    size="sm"
                                    className="btn-light waves-effect waves-light"
                                    onClick={() => {
                                      Excluir(
                                        hydrometer.OID_HIDROMETRO_MASTER_CDMN
                                      );
                                    }}
                                  >
                                    <span>Excluir</span>
                                  </Link>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </Container>
                    <Button
                      color="primary"
                      style={{ borederRadius: "100%", marginLeft: 10 }}
                      onClick={() => {
                        //    setHydrometersFree([]);
                        newMacro(macroPadrao)
                        setSelecionados([])
                        modal2();
                      }}
                    >
                      +
                    </Button>
                  </>
                ) : null}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment >
  );
};

export default editHidrometro;
