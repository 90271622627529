import React, { useState } from "react";
import { Button, Card, CardBody, Col, Row } from "reactstrap";

const AuthTelas = ({ onPasswordCheck }) => {
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false); // Estado para controlar a exibição do erro

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = password === 'telemetria';
    onPasswordCheck(isValid);
    setError(!isValid); // Atualiza o estado de erro com base na validade da senha
  };

  return (
    <Card>
      <CardBody>
        <form onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Col md={12}>
              <h1>Digite sua senha de administrador</h1>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={8} className="mx-auto">
              <input
                className="form-control form-control-lg"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Senha"
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={12} className="text-center">
              <Button color="primary" className="btn-lg" type="submit">
                Entrar
              </Button><br></br><br></br>
              {error && <span style={{ color: 'red' }}>Senha incorreta</span>} {/* Mensagem de erro */}
            </Col>
          </Row>
        </form>
      </CardBody>
    </Card>
  );
};

export default AuthTelas;
